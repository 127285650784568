<template>
  <el-dialog
    :visible.sync="visible"
    width="40%"
    @close="close">
    <div class="w-full flex flex-row mb-2">
      <div class="w-full flex flex-row justify-between text-lg font-bold inline-flex items-center">
        <strong>&nbsp;Seleccionar Fecha</strong>
        <i class="bx bx-x cursor-pointer" style="padding: 4px; font-size: 20px" @click="close"></i>
      </div>
    </div>

    <el-date-picker
      :type="datePickerType"
      placeholder="Fecha"
      v-model="date"
      style="width: 100%;"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      unlink-panels
    ></el-date-picker>

    <div class="w-full text-right mt-4">
      <el-button type="primary" @click="save">
        Guardar
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import _ from "lodash";

export default {
  name: 'Date',
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    isRange: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      date: null,
    };
  },
  computed: {
    datePickerType() {
      return this.isRange ? 'daterange' : 'date';
    }
  },
  methods: {
    close() {
      this.date = null;
      this.$emit('close');
    },
    save() {
      if (
        (this.isRange && !_.isNil(this.date[0]) && !_.isNil(this.date[1])) || !_.isNil(this.date)
      ) {
        this.$emit('on:update', this.date);
        this.close();
      }
    },
  }
}
</script>